import { Autocomplete, Skeleton, TextField } from "@mui/material";
import * as React from "react";
import { useGetSupportedDevicesQuery } from "../../../store/slices/api/setupSlice";

export const CheckTypeSelection = ({ initialVal, callBack }) => {
  const [checkType, setCheckType] = React.useState(null);
  const [typeList, setTypeList] = React.useState(null);
  const {
    data: checkTypeList,
    isLoading,
    isError,
  } = useGetSupportedDevicesQuery();

  React.useEffect(() => {
    console.log("CheckTypeListUseEffect");
    console.log(checkTypeList);
    if (initialVal !== null && checkTypeList != null) {
      setCheckType(checkTypeList.checktypes.find((x) => x.uuid === initialVal));
      setTypeList(checkTypeList.checktypes);
    }
    if (initialVal == null && checkTypeList != null) {
      setCheckType(checkTypeList.checktypes.find((x) => x.name === "RTSP"));
      setTypeList(checkTypeList.checktypes);
    }
  }, [checkTypeList]);

  React.useEffect(() => {
    if (callBack) {
      callBack(checkType);
    }
  }, [checkType]);

  return (
    <>
      {checkTypeList ? (
        <Autocomplete
          value={checkType}
          onChange={(event, newValue) => {
            setCheckType(newValue);
          }}
          options={typeList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Type" />}
        />
      ) : (
        <Skeleton></Skeleton>
      )}
    </>
  );
};

export default CheckTypeSelection;
