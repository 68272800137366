import { Box, Container } from "@mui/material";
import { useTheme } from "@emotion/react";

export const AuthLayout = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default AuthLayout;
