import * as React from "react";
import { useSelector } from "react-redux";
import { useGetDeviceConfigQuery } from "../../../../store/slices/api/setupSlice";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import DeviceDiscoveryDataGrid from "./DeviceDiscoveryDataGrid";
import DeviceManagementDataGrid from "../../Management/DeviceManagement/DeviceManagementDataGrid";

export const DataRetrieval = ({}) => {
  const device = useSelector((state) => state.newDeviceSetup.activeDevice);
  const [skip, setSkip] = React.useState(true);
  const [echoDeviceSerial, setEchoDeviceSerial] = React.useState();
  const {
    data: echoDeviceData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetDeviceConfigQuery(echoDeviceSerial, { skip });

  // const [echoDevices, setEchoDevices] = React.useState();

  React.useEffect(() => {
    if (device) {
      console.log(echoDeviceSerial);
      setEchoDeviceSerial(device.serial);
    }
  }, [device]);

  React.useEffect(() => {
    setSkip(false);
  }, [echoDeviceSerial]);

  function handleRefreshClicked() {
    refetch();
  }

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <div>Device Discovery</div>
      {echoDeviceSerial ? (
        <>
          <DeviceManagementDataGrid
            echoSystemID={echoDeviceSerial}
            allRefresh={true}
          />

          <Button>Save</Button>
        </>
      ) : (
        <>
          <p>
            No additional devices discovered yet. If this is correct, please
            continue. If you think this is in error, please return to the
            previous section and verify the initial connection information.
          </p>
          <Button onClick={handleRefreshClicked}>Refresh</Button>
        </>
      )}
    </>
  );
};

export default DataRetrieval;
