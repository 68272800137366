import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const url = "fakeApi";

const url = process.env.REACT_APP_API_URL;
console.log("URL " + url);
// export const baseQueryWithHeaeders = fetchBaseQuery({
//   baseUrl: url,
//   mode: "cors",
//   prepareHeaders: (headers) => {
//     headers.set("Accept", "application/json");
//     return headers;
//   },
// });

export const apiSlice = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().login.auth.token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
    fetchFn: async (url, options) => {
      const response = await fetch(url, options);
      //console.log(response);
      if (response.status === 401) {
        //console.log("WHY ARE WE HERE");
        // window.location.href = "login";
        // return Promise.reject({ status: 401, data: null });
      }
      return response;
    },
  }),
  endpoints: () => ({}),
});
