import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styled from "@emotion/styled";

const StyledStepper = styled(Stepper)(({theme})=>({
  margin: 'auto',
  padding: "50px"
}));


export const ConfigStepper = ({ activeStep, configSteps }) => {
  const steps = configSteps ? configSteps : [];

  return (
    <StyledStepper activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => {
        return (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        );
      })}
    </StyledStepper>
  );
};

export default ConfigStepper;
