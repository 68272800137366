import React from "react";
import { useGetCheckinDataQuery } from "../../../../store/slices/api/dashboardSlice";
import { Box, Stack, Divider, Typography } from "@mui/material";
import { StyledOverView } from "./StyledOverView";

const tempInfo = {};

function calcTotals(id, array) {
  let counts = {
    total: 0,
    issues: 0,
  };
  for (let i = 0; i < array.length; i++) {
    if (id == null) {
      if (array[i].status === 0) {
        counts.issues++;
      }
      counts.total = array.length;
    } else if (array[i].siteID === id) {
      console.log(array[i].siteID);
      counts.total++;
      if (array[i].status === 0) {
        counts.issues++;
      }
    }
  }
  console.log(counts);
  return counts;
}

function getTotalUniqueServers(array) {
  // let uniqueSystems = [...new Set(array.map((item) => item.echoID))];
  let uniqueSystems = array;
  console.log("Gett Unique Systems");
  console.log(uniqueSystems);
  return uniqueSystems.length;
}

export const OverallView = ({ filterTarget, checkinData, isLoading }) => {
  const [allData, setAllData] = React.useState({});
  const [serverInfo, setServerInfo] = React.useState({});
  const [devicesInfo, setDevicesInfo] = React.useState({});
  const [uniqueSystems, setUniqueSystems] = React.useState({});

  React.useEffect(() => {
    if (checkinData === null) {
      setServerInfo(null);
      setDevicesInfo(null);
      setUniqueSystems(null);
      setAllData(null);
    }
    if (checkinData) {
      let siteID = null;
      if (filterTarget) {
        siteID = filterTarget;
      }
      let tempData = calcTotals(siteID, checkinData["allData"]);
      setServerInfo(
        siteID
          ? checkinData["systems"].filter(
              (sys) => sys.details.siteID === siteID
            )
          : checkinData["systems"]
      );
      setDevicesInfo({ ...tempData });
      setAllData(checkinData["allData"]);
    }
  }, [filterTarget, checkinData]);

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={"space-evenly"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <StyledOverView
          header={serverInfo ? "Monitored Servers" : null}
          data={serverInfo ? serverInfo.length : 0}
        />
        <StyledOverView
          header={devicesInfo ? "Monitored Devices" : null}
          data={devicesInfo ? devicesInfo.total : 0}
          isLoading={isLoading}
        />
        <StyledOverView
          header={devicesInfo ? "Total Issues" : null}
          data={devicesInfo ? devicesInfo.issues : 0}
          isLoading={isLoading}
        />
      </Stack>
    </>
  );
};

export default OverallView;
