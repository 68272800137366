import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
import { Route, Routes, Outlet } from "react-router";
// import { useTheme, ThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import DarkTheme from "./_styles/darkTheme";
import LightTheme from "./_styles/lightTheme";
import ThemeToggler from "./components/ThemeToggler";
import { CssBaseline } from "@mui/material";
import RouteList from "./_data/routes/RouteList";
import AuthLayout from "./components/features/_Layouts/AuthLayout";
import Layout from "./components/features/_Layouts/Layout";
import LoginPage from "./components/features/Login/LoginPage";

function App() {
  const stateTheme = useSelector((state) => state.global.theme);
  const [themeMode, setThemeMode] = React.useState(DarkTheme);

  React.useEffect(() => {
    if (stateTheme === "light") {
      setThemeMode(LightTheme);
    } else {
      setThemeMode(DarkTheme);
    }
  }, [stateTheme]);

  return (
    // <ColorModeContext.Provider value={themeMode}>
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <Routes>
        <Route
          path="login"
          element={
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          }
        >
          <Route index element={<LoginPage />} />
        </Route>
        <Route
          path="/"
          element={
            <Layout NavList={RouteList}>
              <Outlet />
            </Layout>
          }
        >
          {RouteList.map((item, index) => {
            return (
              <Route path={item.path} key={index} element={item.component} />
            );
          })}
        </Route>
      </Routes>
    </ThemeProvider>
    // </ColorModeContext.Provider>
  );
}

export default App;
