import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import CheckTypeSelection from "../../CheckTypeSelection";
import IntervalSelection from "../../IntervalSelecation";

export const AddNewCheckModal = ({ open, onClose, onSubmit }) => {
  const [values, setValues] = React.useState({
    checkType: "",
    checkInterval: 30,
  });
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(values);
    }
    onClose();
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create a New Check</DialogTitle>
        <DialogContent>
          <CheckTypeSelection
            callBack={(val) => setValues({ ...values, checkType: val })}
          />
          <IntervalSelection
            callBack={(val) => setValues({ ...values, checkInterval: val })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="outlined">
            Add Check
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewCheckModal;
