import { Box, Button, Checkbox, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material";

import {
  setUser,
  setAuth,
  logout,
  useUserDataQuery,
  setUserDetails,
} from "../../../store/slices/api/loginSlice";
import { useNavigate } from "react-router-dom";
import { useLoginPostMutation } from "../../../store/slices/api/loginSlice";
import NotificationSnackbar from "../../Alert/NotificationSnackbar";
import LoadingBackdrop from "../../LoadingBackdrop";
import PasswordInput from "../../Inputs/PasswordInput";
import EchoLogo from "../../../echologo.png";
export const LoginPage = () => {
  const firstRender = React.useRef(true);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [loginStatus, setLoginStatus] = React.useState(false);
  const [disableLogin, setDisableLogin] = React.useState(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [failedLoginAttempt, setFailedLoginAttempt] = React.useState(false);
  const [skip, setSkipGetUserData] = React.useState(true);
  const [tryLogin, { isLoading, isError }] = useLoginPostMutation();
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    isSuccess: isUserDataSuccess,
  } = useUserDataQuery(email, { skip });

  let navigate = useNavigate();
  let dispatch = useDispatch();

  function validToLogin() {
    let valid = true;
    setOpenAlert(false);
    if (email === "" || email.length === 0) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (password === "" || password.length === 0) {
      valid = false;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    return valid;
  }
  React.useEffect(() => {
    if (firstRender.current) {
      console.log("First");
      firstRender.current = false;
      return;
    }
    console.log("Checking to enable login button");
    if (emailError || passwordError) {
      setDisableLogin(true);
    }
    if (!emailError && !passwordError) {
      setDisableLogin(false);
    }
  }, [password, email, passwordError, emailError]);
  const onLoginClick = async (e) => {
    e.preventDefault();
    dispatch(logout());
    setFailedLoginAttempt(true);
    if (validToLogin()) {
      console.log("All Good go to login");
      try {
        await tryLogin({
          userEmail: email,
          password: password,
        })
          .unwrap()
          .then((response) => {
            console.log(response);
            dispatch(setUser(email));
            dispatch(setAuth(response.token));
            getUserData();
          });
      } catch {
        dispatch(logout());
        setPassword("");
        setEmail("");
        setOpenAlert(true);
        setLoginStatus(false);
        setSkipGetUserData(true);
        setFailedLoginAttempt(true);
      }
    }
  };

  React.useEffect(() => {
    if (isUserDataSuccess) {
      // console.log("getUserDataQuery Found");
      // console.log(userData);
      dispatch(setUserDetails(userData));
      setOpenAlert(true);
      setLoginStatus(true);
      navigate("../");
    }
  }, [userData, isUserDataSuccess]);

  const getUserData = async (e) => {
    console.log("Get user data");
    setSkipGetUserData(false);
  };

  const onEmailChange = (e) => {
    //console.log("Set Email");
    //console.log(e.target.value);
    setFailedLoginAttempt(false);
    setEmail(e.target.value);
  };

  const handleEmailEntered = () => {
    // console.log("HandleEmail:  " + email);
    let isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    if (!isValid) {
      setEmailError(true);
    } else setEmailError(false);
  };
  const handleRememberMe = (e) => {
    if (e.target.checked === true) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  };

  return (
    <Box sx={{}}>
      <Box
        component={"img"}
        src={EchoLogo}
        sx={{
          height: "200px",
          width: "200px",
          margin: "auto",
          display: "block",
        }}
      />
      <Typography variant="h2" sx={{ padding: "30px 20px" }}>
        E.C.H.O. Portal
      </Typography>
      {failedLoginAttempt && (
        <Typography
          variant="subtitle2"
          sx={{ padding: "15px 85px ", color: "red" }}
        >
          Failed Login. Please try again.
        </Typography>
      )}
      <form onSubmit={onLoginClick}>
        <Grid container sx={{ margin: "auto" }}>
          <Stack sx={{ margin: "auto", width: "100%" }} spacing={2}>
            <TextField
              required
              label="Email"
              value={email}
              onBlur={handleEmailEntered}
              onChange={onEmailChange}
              helperText={emailError && "Please enter valid email"}
              error={emailError}
            />
            {/* <TextField
              required
              label="Password"
              value={password}
              onBlur={() => {
                if (password !== "") setPasswordError(false);
              }}
              onChange={(val) => {
                setPassword(val);
                if (val !== null && val !== "") setPasswordError(false);
              }}
              helperText={passwordError && "Please enter a valid password"}
              error={passwordError}
            /> */}
            <PasswordInput
              required
              label="Password"
              value={password}
              onBlur={() => {
                if (password !== "") setPasswordError(false);
              }}
              onChangeCallBack={(val) => {
                setFailedLoginAttempt(false);
                setPassword(val);
                if (val !== null && val !== "") setPasswordError(false);
              }}
              helperText={passwordError && "Please enter a valid password"}
              error={passwordError}
            />
            {/* <FormGroup>
              <FormControlLabel
                onChange={handleRememberMe}
                control={<Checkbox defaultChecked />}
                label="Remember Me"
              />
            </FormGroup> */}
            <Button
              type="submit"
              disabled={disableLogin}
              onClick={onLoginClick}
            >
              Login
            </Button>
          </Stack>
        </Grid>
      </form>
      <NotificationSnackbar
        open={openAlert}
        status={loginStatus ? "success" : "error"}
        msg={loginStatus ? "Welcome " : "Login Failed"}
      />
      <LoadingBackdrop open={isLoading} />
    </Box>
  );
};

export default LoginPage;
