import { apiSlice } from "./apiSlice";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeDevice: null,
};

export const deviceManagementSlice = createSlice({
  name: "deviceManagement",
  initialState,
  reducers: {
    selectedDevice: (state, action) => {
      console.log(action.payload);
      state.activeDevice = action.payload;
    },
    deselectDevice: (state, action) => {
      state.activeDevice = null;
    },
    updateDevice: (state, action) => {
      console.log("UpdateDevice");
      state.activeDevice = action.payload;
    },
  },
});

export const { selectedDevice, updateDevice, deselectDevice } =
  deviceManagementSlice.actions;
export const deviceManagementReducer = deviceManagementSlice.reducer;

export const deviceManagementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientEchoSystems: builder.query({
      query: (client) => ({ url: `/devices/client/${client}/systems` }),
    }),
    getEchoDevices: builder.query({
      query: (echosystemid) => ({
        url: `/devices/system/${echosystemid}`,
      }),
    }),
    getEchoDevicesWChecks: builder.query({
      query: (echosystemid) => ({
        url: `/devices/system/${echosystemid}/checks`,
      }),
    }),
    updateDeviceConfiguration: builder.mutation({
      query: (content) => ({
        url: `/deviceConfig/updateconfig`,
        method: "POST",
        body: content,
      }),
    }),
    //DeviceChecks
    addDeviceChecks: builder.mutation({
      query: (content) => ({
        url: `/deviceConfig/addChecks`,
        method: "POST",
        body: { ...content, uuid: "", enabled: true, error: "none" },
      }),
    }),
    updateDeviceChecks: builder.mutation({
      query: (content) => ({
        url: `/deviceConfig/updateCheck`,
        method: "POST",
        body: { ...content, deviceid: "" },
      }),
    }),
    deleteDeviceCheck: builder.mutation({
      query: (checkid) => ({
        url: `/DeviceConfig/deleteCheck/${checkid}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetClientEchoSystemsQuery,
  useGetEchoDevicesQuery,
  useGetEchoDevicesWChecksQuery,
  useUpdateDeviceConfigurationMutation,
  useAddDeviceChecksMutation,
  useDeleteDeviceCheckMutation,
  useUpdateDeviceChecksMutation,
} = deviceManagementApi;
