export const configSteps = [
  {
    title: "Register",
    label: "Register ECHO Installation",
    status: "Not Started",
  },
  {
    title: "Configure",
    label: "Configure ECHO",
    status: "Not Started",
  },
  {
    title: "Retrieve",
    label: "Echo Retrieving Data",
    status: "Not Started",
  },
  {
    title: "Verify",
    label: "Verify and Confirm Configuration",
    status: "Not Started",
  },
];
