import * as React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

const StyledBox = styled(Box)(({ theme }) => ({
  height: theme.features.global.main.height,
}));

export const MainBox = ({ children }) => {
  const theme = useTheme();
  const sidebarCollapse = useSelector(
    (state) => state.global.sidebar.collapsed
  );
  const dispatch = useDispatch();
  return <StyledBox>{children}</StyledBox>;
};

export default MainBox;
