import { TextField } from "@mui/material";
import * as React from "react";

export const IPInputField = ({ initialVal, required, disabled, callBack }) => {
  const [IP, setIP] = React.useState(initialVal);
  console.log(IP);
  React.useEffect(() => {
    if (callBack) {
      callBack(IP);
    }
  }, [IP]);
  React.useEffect(() => {
    if (initialVal) {
      setIP(initialVal);
    }
  }, [initialVal]);

  const handleChange = (event) => {
    console.log("IPChanged");
    console.log(event.target.value);
    setIP(event.target.value);
  };

  return (
    <>
      <TextField
        id="IPInputField"
        required={required}
        disabled={disabled}
        label="IP"
        onChange={handleChange}
        value={IP}
      />
    </>
  );
};

export default IPInputField;
