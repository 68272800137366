import { Alert, Button, Box, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePostConfirmDeviceMutation } from "../../../../../store/slices/api/setupSlice";

export const VerifyAndConfirm = () => {
  const device = useSelector((state) => state.newDeviceSetup.activeDevice);
  const [saving, setSaving] = React.useState(false);
  const [saveFinalConfig, { isLoading, isError }] =
    usePostConfirmDeviceMutation();
  const [snackbarStatus, setSnackBarStatus] = React.useState("info");
  const [snackBarText, setSnackBarText] = React.useState("");
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  let navigate = useNavigate();

  const handleConfirm = async () => {
    try {
      setSaving(true);
      await saveFinalConfig(device.serial).unwrap();
      setSnackBarText("Finalized Config");
      setSnackBarStatus("success");
      setShowSnackBar(true);
      navigate("/dashboard");
    } catch {
      setSnackBarText("Could not save config. Please try again.");
      setSnackBarStatus("error");
      setShowSnackBar(true);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Box>
      <h2>Verify and Confirm</h2>
      <h5>All information has been received.</h5>
      <Button onClick={handleConfirm} disabled={saving}>
        Confirm & View Dashboard
      </Button>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        TransitionComponent={Slide}
        onClose={() => setShowSnackBar(false)}
      >
        <Alert severity={snackbarStatus}>{snackBarText}</Alert>
      </Snackbar>
    </Box>
  );
};

export default VerifyAndConfirm;
