import React from "react";
import { useGetCheckinDataQuery } from "../../../../store/slices/api/dashboardSlice";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomDataGrid2 } from "../../../CustomDataGrid2";

const columns = [
  { accessorKey: "siteID", header: "SiteID" },
  { accessorKey: "siteName", header: "Site" },
  {
    accessorKey: "name",
    header: "Device Name",
    align: "center",
    headerAlign: "center",
    Cell: ({ cell, row }) => {
      //console.log(row);
      if (cell.getValue().length > 0) {
        return <Typography>{cell.getValue()}</Typography>;
      }
    },
  },
  // {
  //   accessorKey: "lastCheck",
  //   header: "Updated",
  //   align: "right",
  //   headerAlign: "right",
  // },
  { accessorKey: "ip", header: "IP" },
];

export const DownSystemGrid = ({
  checkinData,
  isLoading,
  isError,
  isSuccess,
}) => {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    if (checkinData) {
      // console.log(checkinData);
      setRows(checkinData["downDevices"]);
    }
  }, [checkinData]);

  return (
    <>
      <CustomDataGrid2
        height={"75vh"}
        title={"All Down Devices"}
        tempRows={rows}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        data={rows}
        columnVisibility={{
          siteID: false,
          siteName: false,
          name: true,
          site: true,
          lastCheck: false,
        }}
      />
    </>
  );
};
