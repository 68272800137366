import { Autocomplete, Skeleton, TextField } from "@mui/material";
import * as React from "react";
import { useGetSupportedDevicesQuery } from "../../../../../store/slices/api/setupSlice";

export const DeviceTypeSelection = ({ initialVal, callBack }) => {
  const [deviceType, setDeviceType] = React.useState(null);
  const {
    data: deviceTypeList,
    isLoading,
    isError,
  } = useGetSupportedDevicesQuery();

  React.useEffect(() => {
    if (initialVal !== null && deviceTypeList != null) {
      setDeviceType(
        deviceTypeList.devicetypes.find((x) => x.uuid === initialVal)
      );
    }
  }, [deviceTypeList]);

  React.useEffect(() => {
    if (callBack) {
      callBack(deviceType);
    }
  }, [deviceType]);

  return (
    <>
      {deviceTypeList ? (
        <Autocomplete
          value={deviceType}
          onChange={(event, newValue) => {
            setDeviceType(newValue);
          }}
          options={deviceTypeList.devicetypes}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Type" />}
        />
      ) : (
        <Skeleton></Skeleton>
      )}
    </>
  );
};

export default DeviceTypeSelection;
