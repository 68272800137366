import { Typography, Box, Divider } from "@mui/material";

import * as React from "react";
import { useSelector } from "react-redux";
import { useGetAllClientQuery } from "../../store/slices/api/clientSlice";

export const ClientDisplay = () => {
  const errorText = "Error Loading"
  const userClientID = useSelector((state) => state.login.details.clientid);
  const [client, setClient] = React.useState({ id: -1, name: "Loading" });
  const {
    data: clientList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllClientQuery();

  React.useEffect(() => {
    if (userClientID && clientList) {
      console.log(clientList);
      console.log(userClientID)
      setClient(clientList.find((x) => x.id === userClientID));
    }
  }, [clientList, userClientID]);
  return (
    <Box>
      <Typography variant="caption" sx={{ fontVariant: "all-small-caps" }}>
        Current Client
      </Typography>
      <Divider />
      <Typography variant="h5">{(client && client.name )?? errorText}</Typography>
    </Box>
  );
};
