import { apiSlice, baseQueryWithHeaeders } from "./apiSlice";

export const checkinApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCheckinData: builder.query({
      query: (clientuuid) => ({ url: `/Checkin/${clientuuid}` }),
    }),
  }),
});

export const { useGetCheckinDataQuery } = checkinApi;
