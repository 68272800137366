import LoginPage from "../../components/features/Login/LoginPage";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DashboardPage from "../../components/features/Dashboard";
import SetupPage from "../../components/features/Setup";
import ManagementPage from "../../components/features/Management";

const RouteList = [
  {
    label: "Login",
    path: "/login",
    icon: <LoginIcon />,
    component: <LoginPage />,
    level: -1,
  },
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <LoginIcon />,
    component: <DashboardPage />,
    level: -1,
  },
  {
    label: "Home",
    path: "/",
    icon: <HomeIcon />,
    component: <DashboardPage />,
    level: 0,
  },
  {
    label: "Management",
    path: "/management",
    icon: <EditIcon />,
    component: <ManagementPage />,
    level: -1,
  },
  {
    label: "Register",
    path: "/register",
    icon: <SettingsSuggestIcon />,
    component: <SetupPage />,
    level: -1,
  },
];

export default RouteList;
