import React from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ConfigStepper from "./ConfigStepper";
import { configSteps } from "./ConfigStepper/configSteps";
import { RegisterNewDevice } from "./Register";
import ConfigureNewDevice from "./Configure";
import DataRetrieval from "./DataRetrieval";
import VerifyAndConfirm from "./Configure/VerifyAndConfirm";
import NotificationSnackbar from "../../Alert/NotificationSnackbar";
import StepperDetailCard from "./StepperCardDetail";


export const SetupPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const device = useSelector((state) => state.newDeviceSetup.activeDevice);
  const [regCompleted, setRegCompleted] = React.useState(false);
  const [initialConfigMade, setInitialConfigMade] = React.useState(false);
  const [deviceDataRetreived, setDeviceDataRetrieved] = React.useState(false);
  const [configConfirmed, setConfigConfirmed] = React.useState(false);
  const [notificationStatus, setNotificationStatus] = React.useState();
  const [notificationMsg, setNotificationMsg] = React.useState("");
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  React.useEffect(() => {
    if (regCompleted) {
      increaseStep();
    }
  }, [regCompleted]);
  React.useEffect(() => {
    if (initialConfigMade) {
      increaseStep();
    }
  }, [initialConfigMade]);

  function increaseStep() {
    if (activeStep < configSteps.length) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(99);
    }
  }
  function decreaseStep() {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      setActiveStep(0);
    }
  }

  const nextStepCallback = (e) => {
    increaseStep();
  };
  const backStepCallback = (e) => {
    decreaseStep();
  };

  function notificationCallBack(params) {
    setNotificationStatus(params.status);
    setNotificationMsg(params.msg);
    setNotificationOpen(true);
  }

  return (
    <Box sx={{ margin: "auto", textAlign: "center" }}>
      <Typography variant="h3">New Device Setup</Typography>
      <ConfigStepper configSteps={configSteps} activeStep={activeStep} />
      <StepperDetailCard>
      {activeStep === 0 && (
        <RegisterNewDevice isSubmittedCallBack={() => setRegCompleted(true)} />
      )}
      {activeStep === 1 && (
        <ConfigureNewDevice
          isSubmittedCallBack={() => setInitialConfigMade(true)}
          notificationCallback={(params) => notificationCallBack(params)}
        />
      )}
      {activeStep === 2 && (
        <DataRetrieval
          isSubmittedCallBack={() => setDeviceDataRetrieved(true)}
        />
      )}
      {activeStep === 3 && (
        <VerifyAndConfirm
          isSubmittedCallBack={() => setConfigConfirmed(true)}
        />
      )}
</StepperDetailCard>
      <NotificationSnackbar
        open={notificationOpen}
        status={notificationStatus}
        msg={notificationMsg}
      />
      <Button onClick={backStepCallback}>Back</Button>
      <Button onClick={nextStepCallback}>Next</Button>
    </Box>
  );
};

export default SetupPage;
