import { createTheme } from "@mui/material/styles";
import baseTheme from "./theme";

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    text: {
      primary: "#e0ebf4",
    },
    mode: "dark",
    background: {
      default: "#001722",
      paper: "#001722",
    },
  },
  customComponents: {
    overview: {
      backgroundColor: "#001722",
      backgroundImage: "linear-gradient(335deg, #001722 29%, #005247 84%)",
    },
  },
});

export default darkTheme;
