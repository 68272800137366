import * as React from "react";
import { Box, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import { selectedDevice } from "../../../../store/slices/api/setupSlice";
import NewDeviceRegistrationForm from "./NewDeviceForm";
import UnregisteredDeviceList from "./UnregisteredDeviceList";
import UnconfirmedDevicesList from "./UnconfirmedDevices";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const RegisterNewDevice = ({ isSubmittedCallBack }) => {
  const currentDevice = useSelector(
    (state) => state.newDeviceSetup.activeDevice
  );
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {}, [currentDevice]);

  const DeviceSelectCallBack = (e) => {
    console.log("DeviceSelectCallback");
    console.log(e);
    dispatch(selectedDevice(e));
  };
  return (
    <Box sx={{ margin: "auto", width: "50%" }}>
      {currentDevice ? (
        <>
          {/* <Button>Deselect</Button> */}
          <NewDeviceRegistrationForm
            isSubmittedCallBack={isSubmittedCallBack}
            device={currentDevice}
          />
        </>
      ) : (
        <Box>
          <Tabs centered value={value} onChange={handleTabChange}>
            <Tab label={"Unregistered"} />
            <Tab label={"Pending Confirmation"} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UnregisteredDeviceList
              callback={(device) => DeviceSelectCallBack(device)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UnconfirmedDevicesList
              callback={(device) => DeviceSelectCallBack(device)}
            />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};
