import { Autocomplete, Skeleton, TextField } from "@mui/material";
import * as React from "react";
import { useGetSupportedDevicesQuery } from "../../../../store/slices/api/setupSlice";

export const VendorSelection = ({ initialVal, callBack }) => {
  const [vendor, setVendor] = React.useState(null);
  const [vendorList, setVendorList] = React.useState(null);
  const { data, isLoading, isError } = useGetSupportedDevicesQuery();

  React.useEffect(() => {
    console.log("VerndorTypeListUseEffect");
    console.log(data);
    if (initialVal !== null && data != null) {
      setVendor(data.vendors.find((x) => x.uuid === initialVal));
      setVendorList(data.vendors);
    }
    if (initialVal == null && data != null) {
      setVendor(data.vendors.find((x) => x.name === "RTSP"));
      setVendorList(data.vendoors);
    }
  }, [vendorList]);

  React.useEffect(() => {
    if (callBack) {
      callBack(vendor);
    }
  }, [vendor]);

  return (
    <>
      {data ? (
        <Autocomplete
          value={vendor}
          onChange={(event, newValue) => {
            setVendor(newValue);
          }}
          options={vendorList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Type" />}
        />
      ) : (
        <Skeleton></Skeleton>
      )}
    </>
  );
};

export default VendorSelection;
