import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckIcon from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";
import Close from "@mui/icons-material/Close";

export const getStatusDisplayValues = (cellValue) => {
  let color = "";
  let icon = null;
  let statusStr = "Unknown";

  switch (cellValue) {
    case 0:
      color = "error";
      icon = <DangerousIcon />;
      statusStr = "FAILED";
      break;

    case 1:
      color = "success";
      icon = <CheckIcon />;
      statusStr = "GOOD";
      break;
    case 3:
      color = "info";
      icon = <Close />;
      statusStr = "NO CHECKS";
      break;
    case 2:
      color = "warning";
      icon = <Warning />;
      statusStr = "WARNING";
      break;
    default:
      color = "info";
      icon = <Close />;
      statusStr = "UNKNOWN";
      break;
  }
  return { color: color, icon: icon, status: statusStr };
};

export default getStatusDisplayValues;
