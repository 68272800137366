import React from "react";
import { useGetCheckinDataQuery } from "../../../../store/slices/api/dashboardSlice";
import { Box, Skeleton, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomDataGrid2 } from "../../../CustomDataGrid2";
import CheckIcon from "@mui/icons-material/Check";
import DangerousIcon from "@mui/icons-material/Dangerous";
import getStatusDisplayValues from "../../../../utils/getStatusDisplayValues";

const columns = [
  {
    accessorKey: "status",
    header: "Status",
    size: 150,
    Cell: ({ cell }) => {
      let CellDisplayValues = getStatusDisplayValues(cell.getValue());
      return (
        <Chip
          color={CellDisplayValues.color}
          label={CellDisplayValues.status}
          variant="outlined"
          icon={CellDisplayValues.icon}
        />
      );
    },
    flex: 0.25,
  },
  { accessorKey: "serial", header: "ID", size: 150 },
  { accessorKey: "name", header: "Device Name", size: 150 },
  { accessorKey: "ip", header: "IP" },
];
const tempRows = [];

export const DetailsCheckinDataGrid = ({
  checkinData,
  isLoading,
  isError,
  isSuccess,
  serverData,
  handleDeselectClick,
  filterTarget,
}) => {
  const [rows, setRows] = React.useState(tempRows);
  const [system, setSystem] = React.useState();
  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    if (checkinData && system && system.length !== 0) {
      // console.log("HERE");
      // console.log(system);
      //console.log(checkinData);
      let targetSerial = system.details ? system.details.serial : system.serial;
      let systems = checkinData["systems"];
      let target = systems.find((item) => item.serial === targetSerial);
      // console.log("FInding Target");
      //console.log(target);

      let encodersExist = "encoders" in system;
      // console.log("Encoders Exist");
      // console.log(encodersExist);
      if (!encodersExist) {
        // console.log(target["encoders"]);
        //   console.log(system);
        target = target["encoders"].find(
          (item) => item.details.uuid === system.details.uuid
        );
        //  console.log("FInding Target2");

        //  console.log(target);
      }
      if (!target) {
        setRows([]);
      } else {
        setRows(target.devices);
      }
    }
  }, [checkinData, system]);

  React.useEffect(() => {
    setRows([]);
    setSystem();
    setTitle("");
  }, [filterTarget]);

  React.useEffect(() => {
    // console.log("serverData");
    //  console.log(serverData);
    let detailsTitle = "";

    setSystem(serverData);
    if (serverData) {
      if (serverData.name) {
        detailsTitle = serverData.name;
      } else if (serverData.details) {
        detailsTitle = serverData.details.name;
        if (detailsTitle.length === 0)
          detailsTitle = "Encoder " + serverData.details.ip;
      }

      setTitle(detailsTitle + " Devices");
    }
    if (serverData == null) {
      setTitle("");
    }

    // console.log("+++++++++++++++++++++");
  }, [serverData]);

  return (
    <>
      <CustomDataGrid2
        title={title}
        height={"50vh"}
        columnVisibility={{ serial: false }}
        deselect={system !== undefined && system.name !== undefined}
        deselectClicked={handleDeselectClick}
        sorting={[
          {
            id: "status",
            desc: false,
          },
        ]}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        data={rows}
        // styledField={"status"}
        // noRowsOverlayText={"Please select a server"}
      />
    </>
  );
};
