import { Slider, Typography } from "@mui/material";
import * as React from "react";

export const IntervalSelection = ({ initialVal, callBack }) => {
  const [interval, setInterval] = React.useState(initialVal ?? 30);

  const handleChange = (e, newValue) => {
    setInterval(newValue);
    if (callBack) {
      callBack(newValue);
    }
  };
  return (
    <>
      <Typography> Interval: {interval}</Typography>
      <Slider
        defaultValue={initialVal ?? 30}
        valueLabelDisplay="auto"
        step={15}
        marks
        min={30}
        max={120}
        onChange={handleChange}
      />
    </>
  );
};

export default IntervalSelection;
