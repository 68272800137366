import { apiSlice } from "./apiSlice";
import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   user: "malcolma@ilsny.com",

//   details: {},
//   auth: {
//     token:
//       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbGNvbG1hQGlsc255LmNvbSIsInVuaXF1ZV9uYW1lIjoiZFF5dzBjLysvWitPQ1RGNXZFWlZJWlhYblVCdGszQnMrSXRoWlV3QXBIRmFpZm0rWS9xOTFRWVRTYXhBOVVFVXgxRkVmWDFZeVZXQzd4aW56V283ZkE9PSIsIm5iZiI6MTY4NDMzMDQyNCwiZXhwIjoxNjg0MzU5NDA0LCJpYXQiOjE2ODQzMzA2MDQsImlzcyI6IkludHJhbG9naWNTb2x1dGlvbnMiLCJhdWQiOiJIZWFsdGhQb3J0YWwifQ.XY7Q5lJWY3h_BqbPATpGLJidTn5o87zMwjowrRuxJeo",
//     isAuthenticated: false,
//   },
// };

const initialState = {
  user: "",

  details: {},
  auth: {
    token: "",
    isAuthenticated: false,
  },
};

export const loginSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log(action.payload);
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      console.log(action.payload);
      state.details = action.payload;
    },
    logout: (state) => {
      state.user = {};
      state.auth.token = "";
      state.details = {};
      state.auth.isAuthenticated = false;
    },
    setAuth: (state, action) => {
      console.log(action.payload);
      state.auth.token = action.payload;
      state.auth.isAuthenticated = true;
    },
  },
});

export const { setUser, setAuth, logout, setUserDetails } = loginSlice.actions;
export const loginReducer = loginSlice.reducer;

export const loginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginPost: builder.mutation({
      query: (creds) => ({ url: `/login`, method: "POST", body: creds }),
    }),
    logoutPost: builder.mutation({
      query: (userID) => ({ url: `/logoff/${userID}`, method: "DELETE" }),
    }),
    userData: builder.query({
      query: (userEmail) => ({
        url: `/User`,
        method: "POST",
        body: JSON.stringify(userEmail),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const { useLoginPostMutation, useLogoutPostMutation, useUserDataQuery } =
  loginApi;
