import * as React from "react";
import styled from "@emotion/styled";
import { Container, Box } from "@mui/material";

const StyledFooter = styled(Box)(({ theme }) => ({
  ...theme.features.global.footer,
  backgroundColor: theme.palette.background.default,
}));

const FooterText = styled.p`
  // font-size: 0.8rem;
  // margin-bottom: 0;
`;

export const Footer = ({ theme }) => {
  return (
    <StyledFooter theme={theme}>
      <FooterText>
        Copyright © 2023 Intralogic Solutions, LLC. All rights reserved.
      </FooterText>
    </StyledFooter>
  );
};

export default Footer;
