import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CustomDataGrid2 from "../../../CustomDataGrid2";
import { Close, Warning } from "@mui/icons-material";
import getStatusDisplayValues from "../../../../utils/getStatusDisplayValues";

const calcCellDisplayValues = (cellValue) => {
  let color = "";
  let icon = null;
  let statusStr = "Unknown";

  switch (cellValue) {
    case 0:
      color = "error";
      icon = <DangerousIcon />;
      statusStr = "FAILED";
      break;

    case 1:
      color = "success";
      icon = <CheckIcon />;
      statusStr = "GOOD";
      break;
    case 3:
      color = "info";
      icon = <Close />;
      statusStr = "NO CHECKS";
      break;
    case 2:
      color = "warning";
      icon = <Warning />;
      statusStr = "WARNING";
      break;
    default:
      color = "info";
      icon = <Close />;
      statusStr = "UNKNOWN";
      break;
  }
  return { color: color, icon: icon, status: statusStr };
};

const columns = [
  { accessorKey: "echoID", header: "ID", size: 150 },
  {
    accessorKey: "details.status",
    header: "Status",
    size: 150,
    Cell: ({ cell }) => {
      let CellDisplayValues = getStatusDisplayValues(cell.getValue());
      return (
        <Chip
          color={CellDisplayValues.color}
          label={CellDisplayValues.status}
          variant="outlined"
          icon={CellDisplayValues.icon}
        />
      );
    },
    flex: 0.25,
    sortingFn: (rowA, rowB) => {
      // console.log(rowB.original.details.status);

      let valA = rowA.original.details.status;
      let valB = rowB.original.details.status;

      if (valA === valB) {
        // console.log("ValsEqual");

        // console.log("+++++++++++++++++++++++++++");
        return 0;
      }

      if (valA === 0) {
        // console.log("valA === 0");
        // console.log("+++++++++++++++++++++++++++");
        return -1;
      } else if (valA > 0) {
        // console.log("Row Sorting");

        // console.log("---------------");
        // console.log("A: " + valA);
        // console.log("B: " + valB);
        if (valB === 0 || valB === 2) {
          // console.log("valB === 0 || 2");
          // console.log("+++++++++++++++++++++++++++");
          return 1;
        } else if (valB === 1 || valB > 2) {
          // console.log("valB > 2");
          // console.log("+++++++++++++++++++++++++++");
          return -1;
        }
      }
      // console.log("WELP");
      // console.log("+++++++++++++++++++++++++++");
      return 0;
    },
  },
  {
    accessorKey: "details.siteName",
    header: "Site",
    size: 150,

    flex: 1,
  },
  { accessorKey: "siteID", header: "SiteID", size: 150 },
  {
    accessorKey: "details.name",
    header: "Server Name",
    size: 150,
    Cell: ({ cell, row }) => {
      //console.log(row);
      if (cell.getValue().length > 0) {
        return <Typography>{cell.getValue()}</Typography>;
      } else {
        return (
          <Typography>
            Encoder {row.original.details ? row.original.details.ip : ""}
          </Typography>
        );
      }
    },
  },
];

export const ServerCheckinDataGrid = ({
  checkinData,
  isLoading,
  isError,
  isSuccess,
  selectedRowCallBack,
  filterTarget,
}) => {
  //console.log(checkinData);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  React.useEffect(() => {
    if (checkinData) {
      if (filterTarget == null) {
        setRows(checkinData["systems"]);
      } else {
        setRows(
          checkinData["systems"].filter(
            (system) =>
              system.details !== null && system.details.siteID === filterTarget
          )
        );
      }
    }
  }, [checkinData, filterTarget]);

  React.useEffect(() => {
    if (selectedRow) {
      // console.log(selectedRow);
      if (selectedRowCallBack) {
        selectedRowCallBack(selectedRow);
      }
    }
  }, [selectedRow]);

  return (
    <>
      <CustomDataGrid2
        title={"Systems"}
        height={"50vh"}
        tempRows={rows}
        columns={columns}
        isLoading={isLoading}
        sorting={[
          {
            id: "details.status",
          },
          {
            id: "details.siteName",
            desc: false,
          },
        ]}
        columnVisibility={{
          echoID: false,
          siteID: false,
          name: true,
          site: false,
        }}
        selectedRowCallback={(data) => setSelectedRow(data)}
        isError={isError}
        enableRowSelection={false}
        getSubRows={(originalRow) => originalRow.encoders}
        multiRowSelection={false}
        data={rows ?? []}
        enableExpanding={true}
        styledField={"status"}
      />
    </>
  );
};

export default ServerCheckinDataGrid;
