import { Autocomplete, Skeleton, TextField } from "@mui/material";
import * as React from "react";
import { useGetSupportedDevicesQuery } from "../../../../../store/slices/api/setupSlice";

export const VendorTypeSelection = ({ initialVal, callBack }) => {
  const [vendor, setVendor] = React.useState(null);
  const { data, isLoading, isError } = useGetSupportedDevicesQuery();
  React.useEffect(() => {
    if (data) {
      if (initialVal !== null && data != null) {
        setVendor(data.vendors.find((x) => x.uuid === initialVal));
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (callBack) {
      callBack(vendor);
    }
  }, [vendor]);

  return (
    <>
      {data ? (
        <Autocomplete
          value={vendor}
          onChange={(event, newValue) => {
            setVendor(newValue);
          }}
          options={data.vendors || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Vendor" />}
        />
      ) : (
        <Skeleton></Skeleton>
      )}
    </>
  );
};

export default VendorTypeSelection;
