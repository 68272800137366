import * as React from "react";
import {
  Stack,
  FormGroup,
  TextField,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { ClientSelect } from "../../../../ClientSite/ClientSelection";
import SiteSelect from "../../../../ClientSite/SiteSelection";
import {
  updateDevice,
  useRegisterDeviceMutation,
} from "../../../../../store/slices/api/setupSlice";

export const NewDeviceRegistrationForm = ({ device, isSubmittedCallBack }) => {
  const dispatch = useDispatch();
  const [clientSelected, setClientSelected] = React.useState(
    device.clientid ? device.clientid : null
  );
  const [clientSelectError, setClientSelectError] = React.useState(false);
  const [siteSelected, setSiteSelected] = React.useState(
    device.siteid ? device.siteid : null
  );
  const [siteSelectError, setSiteSelectError] = React.useState(false);

  const [deviceName, setDeviceName] = React.useState(
    device ? device.name : "Echo-" + device.serial
  );
  const [deviceNameError, setDeviceNamError] = React.useState(false);
  const [validToSave, setValidToSave] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [registerDevice, { isLoading }] = useRegisterDeviceMutation();

  const handleNameBlur = (e) => {
    //TODO: Check if Name already exist
    setDeviceName(e.target.value);
  };
  React.useEffect(() => {
    if (clientSelected) {
      setClientSelectError(false);
    } else {
      setClientSelectError(true);
    }
  }, [clientSelected]);
  React.useEffect(() => {
    if (siteSelected) {
      setSiteSelectError(false);
    } else {
      setSiteSelectError(true);
    }
  }, [siteSelected]);
  React.useEffect(() => {
    if (deviceName) {
      setDeviceNamError(false);
    } else {
      setDeviceNamError(true);
    }
  }, [deviceName]);
  React.useEffect(() => {
    if (deviceNameError || clientSelectError || siteSelectError) {
      setValidToSave(false);
    } else {
      setValidToSave(true);
    }
  }, [clientSelectError, siteSelectError, deviceNameError]);

  async function checkIfValidToSave() {
    console.log("DeviceName:" + deviceName);
    console.log("Client:" + clientSelected);
    console.log(clientSelected);
    console.log("Site:" + siteSelected);
    console.log(siteSelected);
    if (deviceName == null) {
      setDeviceNamError(true);
    }
    if (clientSelected == null) {
      setClientSelectError(true);
    }
    if (siteSelected == null) {
      setSiteSelectError(true);
    }
    if (clientSelectError || siteSelectError || deviceNameError) {
      setValidToSave(false);
    } else {
      setValidToSave(true);
    }
    return validToSave;
  }
  React.useEffect(() => {
    if (isLoading) {
      console.log("Loading");
    }
  }, [isLoading]);

  const onSaveClick = async () => {
    console.log("Save Clicked");
    setSaving(true);
    let res = await checkIfValidToSave();
    console.log(res);
    if (!res) {
      setSaving(false);
      return;
    }
    // const response = await registerDevice({ device: device }).unwrap();
    // console.log(response);
    //TODO:Error handling from response
    let updatedDevice = { ...device };
    updatedDevice.registered = true;
    updatedDevice.name = deviceName;
    updatedDevice.client = clientSelected.id;
    updatedDevice.site = siteSelected.id;
    dispatch(updateDevice(updatedDevice));
    if (isSubmittedCallBack) {
      isSubmittedCallBack(updatedDevice);
    }
  };

  return (
    <Stack spacing={2}>
      <h4>Register Device</h4>
      <TextField
        required
        label="Name"
        value={deviceName}
        onBlur={handleNameBlur}
        onChange={(event) => {
          setDeviceName(event.target.value);
        }}
      />
      <TextField disabled label="Device Serial#" value={device.serial} />
      <ClientSelect
        initialVal={device.clientid || null}
        clientSelectedCallback={(client) => setClientSelected(client)}
      />
      <SiteSelect
        siteSelectedCallBack={(site) => setSiteSelected(site)}
        clientID={clientSelected ? clientSelected.id : null}
        initialVal={device.siteid || null}
      />
      <Button disabled={!validToSave || saving} onClick={onSaveClick}>
        Save
      </Button>
    </Stack>
  );
};

export default NewDeviceRegistrationForm;
