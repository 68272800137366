import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import Header from "./header";
import Footer from "./footer";
import SidebarNav from "./SideBarNav";
import MainBox from "./MainBox";
const StyledLayoutContainer = styled(Box)(({ theme }) => ({}));

export const Layout = ({ NavList, children }) => {
  const theme = useTheme();
  const isAuthenticated = useSelector(
    (state) => state.login.auth.isAuthenticated
  );
  const sidebarCollapse = useSelector(
    (state) => state.global.sidebar.collapsed
  );

  return (
    <>
      {!isAuthenticated ? (
        <Navigate to="login" replace={true} />
      ) : (
        <StyledLayoutContainer
          theme={theme}
          sx={{
            background: theme.background,
            flexGrow: 1,
            padding: theme.spacing(2),
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: theme.features.global.sidebar.width,
            ...(!sidebarCollapse && {
              transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
              marginLeft: 0,
            }),
          }}
        >
          <Header theme={theme} />
          <SidebarNav theme={theme} />
          <MainBox>{children}</MainBox>
          <Footer theme={theme} />
        </StyledLayoutContainer>
      )}
    </>
  );
};

export default Layout;
