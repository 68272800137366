import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleSidebarCollapse } from "../../store/slices/globalSlice";

export const DrawerToggler = () => {
  const sidebarCollapse = useSelector(
    (state) => state.global.sidebar.collapsed
  );
  const dispatch = useDispatch();
  return (
    <>
      <IconButton onClick={() => dispatch(toggleSidebarCollapse())}>
        {sidebarCollapse ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>
    </>
  );
};

export default DrawerToggler;
