import { GridToolbarContainer, GridRowModes } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Divider, Typography } from "@mui/material";

export function AddCheckToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const handleClick = () => {
    const id = 1;
    setRows((oldRows) => [
      ...oldRows,
      { id, enabled: true, type: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "type" },
    }));
  };

  return (
    <>
      <Typography sx={{ textAlign: "center" }}>Device Checks</Typography>
      <Divider />
      <GridToolbarContainer>
        <Button startIcon={<AddIcon />} onClick={handleClick}>
          Add Check
        </Button>
      </GridToolbarContainer>
    </>
  );
}

export default AddCheckToolbar;
