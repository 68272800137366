import { apiSlice } from "./apiSlice";

export const clientAndSiteApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClient: builder.query({
      query: () => ({ url: `/clients` }),
    }),
    getAllSites: builder.query({
      query: (clientID) => ({ url: `/clients/${clientID}/Sites` }),
    }),
  }),
});

export const { useGetAllClientQuery, useGetAllSitesQuery } = clientAndSiteApi;
