import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemButton,
  Button,
  Skeleton,
  Typography,
} from "@mui/material";

import {
  selectedDevice,
  useGetClientEchoSystemsQuery,
} from "../../../../store/slices/api/managementSlice";

export const DeviceSelectionList = ({}) => {
  const clientID = useSelector((state) => state.login.details.clientid);
  const dispatch = useDispatch();
  const activeDevice = useSelector(
    (state) => state.deviceManagement.activeDevice
  );
  const {
    data: echoSystems,
    isLoading,
    isError,
    refetch
  } = useGetClientEchoSystemsQuery(clientID);

  function handleDeviceClick(device) {
    console.log(device);
    dispatch(selectedDevice(device));
  }
  React.useEffect(() => {
    console.log(echoSystems);
  }, [echoSystems]);

  if (isLoading) {
    return (
      <>
        <h5>Retrieving data.</h5>
        <h5>Please wait</h5>
      </>
    );
  }
  if (isError) {
    return (
      <>
        <p>Error Loading Data Please refresh</p>
        <Button onClick={refetch}>Refresh</Button>
      </>
    );
  }



  return (
    <>
      <Typography>Device Selection</Typography>
      <List>
        {echoSystems ? (
          echoSystems.map((device, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleDeviceClick(device)}
            >
              {device.name}
            </ListItemButton>
          ))
        ) : (
          <Skeleton width={"200px"} />
        )}
      </List>
    </>
  );
};

export default DeviceSelectionList;
