import * as React from "react";
import styled from "@emotion/styled";
import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Container,
  IconButton,
  Tooltip,
  Icon,
  Toolbar,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import ThemeToggler from "../../../ThemeToggler";
import DrawerToggler from "../../../DrawerToggler";
import AccountMenu from "./AccountMenu";
import ClientSelect from "../../../ClientSite/ClientSelection";
import { ClientDisplay } from "../../../ClientSite/ClientDisplay";

const StyledHeader = styled(AppBar)(({ theme }) => ({
  ...theme.features.global.header,
}));

export const Header = ({ theme }) => {
  return (
    <>
      <StyledHeader theme={theme} position="fixed">
        <Toolbar>
          <DrawerToggler sx={{ float: "left" }} />
          <ClientDisplay />
          <Box sx={{ flexGrow: 1 }} />

          <ThemeToggler sx={{ float: "right" }} />
          {/* <Icon>
            <NotificationsIcon />
          </Icon> */}
          <AccountMenu />
        </Toolbar>
      </StyledHeader>
      <Toolbar />
    </>
  );
};

export default Header;
