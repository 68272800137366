import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeviceManagementDataGrid from "./DeviceManagementDataGrid";
import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { deselectDevice } from "../../../../store/slices/api/managementSlice";
export const DeviceManagement = () => {
  const dispatch = useDispatch();
  const activeDevice = useSelector(
    (state) => state.deviceManagement.activeDevice
  );
  const deselectClicked = () => {
    console.log("deselectClicked");
    dispatch(deselectDevice());
  };

  if (activeDevice === null) {
    return (
      <>
        <Typography>Please return to device selection</Typography>
        <Button>Device Selection</Button>
      </>
    );
  }

  return (
    <>
      <Stack direction={"row"}>
        <Typography>Current Device: {activeDevice.name}</Typography>
        <IconButton
          aria-label="Deselect Device"
          size="small"
          onClick={deselectClicked}
        >
          <Close fontSize="inherit" />
        </IconButton>
      </Stack>
      <DeviceManagementDataGrid echoSystemID={activeDevice.serial} />
    </>
  );
};
export default DeviceManagement;
