import * as React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

export const SideBarItem = ({ item }) => {
  return (
    <ListItemButton to={item.path} component={Link}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText>{item.label}</ListItemText>
    </ListItemButton>
  );
};

export default SideBarItem;
