import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Stack,
  TextField,
  Box,
  Divider,
  Typography,
  Button,
  Card,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import IPInputField from "../../../../Inputs/IPInputField/IPInputField";
import { DeviceTypeSelection } from "../DeviceTypeSelection";
import VendorTypeSelection from "../VendorSelection";
import { NewDeviceConfigurationFormStyle } from "./style";
import { ModelSelection } from "../ModelSelection";
import {
  useRegisterDeviceMutation,
  useGetDeviceConfigQuery,
  useGetSupportedDevicesQuery,
  useSetNewConfigMutation,
} from "../../../../../store/slices/api/setupSlice";

export const NewDeviceRegistrationForm = ({
  isSubmittedCallBack,
  notificationCallback,
}) => {
  const [skip, setSkip] = React.useState(true);
  const device = useSelector((state) => state.newDeviceSetup.activeDevice);
  const [saveNewConfig, { isLoading }] = useRegisterDeviceMutation();

  React.useEffect(() => {
    if (device.devices != null) {
      setFormData({
        ...formData,
        type: device.devices.type,
        vendor: device.devices.vendor,
        model: device.devices.model,
        ip: device.devices.ip,
        port: device.devices.port,
        username: device.devices.username,
        password: device.devices.password,
      });
    }
    setSkip(false);
  }, [device]);

  const [formData, setFormData] = useState({
    type: null,
    vendor: null,
    model: "",
    ip: null,
    port: null,
    username: null,
    password: null,
  });

  const onSaveClick = async () => {
    try {
      let configPayload = {
        enabled: false,
        ...device,
        ...formData,
      };
      console.log(configPayload);
      await saveNewConfig(configPayload)
        .unwrap()
        .then((response) => {
          if (notificationCallback) {
            notificationCallback({ status: "success", msg: "Config Saved" });
          }
          if (isSubmittedCallBack) {
            isSubmittedCallBack();
          }
        });
    } catch {
      if (notificationCallback) {
        notificationCallback({ status: "error", msg: "Failed Saving Config" });
      }
    }
  };

  // React.useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  const validToSave = useMemo(() => {
    return !Object.values(formData).some((value) => value === null);
  }, [formData]);

  if (device == null) {
    return (
      <Typography>
        Please return to step 1 and select a device to configure
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, margin: "auto" }}>
      <Typography variant={"h5"}>
        Configuring {device.name} ({device.serial}) Device
      </Typography>
      <Grid container sx={{ alignItems: "center", padding: "40px" }}>
        <Grid xs={4} sx={{ padding: "10px" }}>
          <Card>
            <Stack spacing={2} margin={"10px"}>
              <Typography variant={"h6"}>Device Details</Typography>

              <DeviceTypeSelection
                initialVal={formData.type || null}
                callBack={(type) => setFormData({ ...formData, type })}
              />
              <VendorTypeSelection
                initialVal={formData.vendor || null}
                callBack={(vendor) => setFormData({ ...formData, vendor })}
              />
              {formData.type && formData.type.name === "Encoder" && (
                <ModelSelection
                  initialVal={formData.model || null}
                  callBack={(model) => setFormData({ ...formData, model })}
                />
              )}
            </Stack>
          </Card>
        </Grid>
        <Grid xs={4} sx={{ padding: "10px" }}>
          <Card>
            <Stack spacing={2} margin={"10px"}>
              <Typography variant={"h6"}>Network</Typography>

              <IPInputField
                required
                initialVal={formData.ip}
                callBack={(ip) => setFormData({ ...formData, ip })}
              />
              <TextField
                required
                label="Port"
                value={formData.port}
                onChange={(event) =>
                  setFormData({ ...formData, port: event.target.value })
                }
              />
            </Stack>
          </Card>
        </Grid>
        <Grid xs={4} sx={{ padding: "10px" }}>
          <Card>
            <Stack spacing={2} margin={"10px"}>
              <Typography variant={"h6"}>Credentials</Typography>

              <TextField
                required
                label="Username"
                value={formData.username}
                onChange={(event) =>
                  setFormData({ ...formData, username: event.target.value })
                }
              />
              <TextField
                required
                label="Password"
                value={formData.password}
                onChange={(event) =>
                  setFormData({ ...formData, password: event.target.value })
                }
              />
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Button disabled={!validToSave} onClick={onSaveClick}>
        Save
      </Button>
    </Box>
  );
};

export default NewDeviceRegistrationForm;
