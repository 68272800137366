import React from "react";
import { useSelector } from "react-redux";
import { DetailsCheckinDataGrid } from "./DetailsCheckinDataGrid";
import { DownSystemGrid } from "./DownSystemGrid";
import ServerCheckinDataGrid from "./ServerCheckInDataGrid";
import { Box, Button, Chip, Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import OverallView from "./OverallView";
import { SearchBar } from "./SearchBox";
import { useGetAllSitesQuery } from "../../../store/slices/api/clientSlice";
import SiteSelect from "../../ClientSite/SiteSelection";
import { useGetCheckinDataQuery } from "../../../store/slices/api/dashboardSlice";

export const DashboardPage = () => {
  const [selectedServer, setSelectedServer] = React.useState();
  const clientID = useSelector((state) => state.login.details.clientid);
  const [allServerData, setAllServerData] = React.useState({
    issues: 10,
    total: 200,
  });
  const [allCameraData, setAllCameraData] = React.useState({
    issues: 100,
    total: 1200,
  });
  const [skipSiteData, setSkipSiteData] = React.useState(true);
  const [sites, setSites] = React.useState([]);
  const [filterTarget, setFilterTarget] = React.useState(null);

  const {
    data: siteList,
    isLoading,
    isError,
  } = useGetAllSitesQuery(clientID, { skip: skipSiteData });
  const {
    data: checkinData,
    isLoading: checkinDataLoading,
    isError: checkinDataError,
    isSuccess: checkinDataSuccess,
  } = useGetCheckinDataQuery(clientID, { pollingInterval: 30000 });

  React.useEffect(() => {
    setSites(siteList);
  }, [siteList]);
  React.useEffect(() => {
    console.log(filterTarget);
  }, [filterTarget]);

  React.useEffect(() => {
    if (checkinDataSuccess) setSkipSiteData(false);
  }, [checkinDataSuccess]);

  function MakeFilter(target) {
    if (target == null) {
      setFilterTarget(null);
    } else {
      console.log(target);
      setFilterTarget(target.id);
    }
  }

  return (
    <Box sx={{ flexGrow: 1, maxHeight: "inherit" }}>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={6} sm={8} md={8}>
          <Grid item container>
            <Grid item xs={12}>
              <Stack
                // direction={"row"}
                spacing={2}
                justifyContent={"center"}
                // alignItems={"space-evenly"}
              >
                <OverallView
                  checkinData={checkinData}
                  isLoading={checkinDataLoading}
                  filterTarget={filterTarget}
                />
                <SearchBar
                  dataToSearch={sites}
                  getOptionLabel={(option) => option.name}
                  searchCallBack={(val) => MakeFilter(val)}
                  isLoading={isLoading}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <ServerCheckinDataGrid
                checkinData={checkinData}
                isLoading={checkinDataLoading}
                isError={checkinDataError}
                isSuccess={checkinDataSuccess}
                selectedRowCallBack={(server) => setSelectedServer(server)}
                filterTarget={filterTarget}
              />
            </Grid>
            <Grid item xs={6}>
              <DetailsCheckinDataGrid
                checkinData={checkinData}
                isLoading={checkinDataLoading}
                isError={checkinDataError}
                isSuccess={checkinDataSuccess}
                serverData={selectedServer}
                handleDeselectClick={() => {
                  setSelectedServer();
                }}
                filterTarget={filterTarget}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} sm={4} md={4}>
          <DownSystemGrid
            checkinData={checkinData}
            isLoading={checkinDataLoading}
            isError={checkinDataError}
            isSuccess={checkinDataSuccess}
            filterTarget={filterTarget}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
