import React from "react";
import { Box, Autocomplete, Skeleton, TextField } from "@mui/material";

export const SearchBar = ({
  dataToSearch,
  getOptionLabel,
  searchCallBack,
  isLoading,
}) => {
  const [searchArr, setSearchArr] = React.useState([]);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    if (dataToSearch) {
      setSearchArr(dataToSearch);
    }
  }, [dataToSearch]);

  React.useEffect(() => {
    if (searchCallBack) {
      searchCallBack(value);
    }
  }, [value]);

  if (isLoading) {
    return <Skeleton minwidth={"300px"} />;
  }

  return (
    <>
      <Autocomplete
        sx={{ minwidth: "300px" }}
        disablePortal
        options={searchArr}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Asset Search" />}
      />
    </>
  );
};
