export const baseTheme = {
  features: {
    global: {
      header: {
        minHeight: "60px",
      },
      footer: {
        height: "4vh",
        position: "fixed",
        bottom: 0,
        fontSize: ".7em",
        textAlign: "center",
        width: "100%",
      },
      main: {
        maxHeight: "85vh",
      },
      sidebar: {
        width: "200px",
        padding: "5px",
      },
      customforms: {
        configuration: {
          spacing: 2,
        },
      },
    },
    customDataGrid: {
      width: "450px",
      height: "auto",
      columns: {
        siteNames: {
          width: "200px",
        },
        times: {
          width: "100px",
        },
      },
    },
  },
  colors: {
    red: "#8F0000",
    lightBlue: "#F6FAFF",
    blue: "#013870",
    green: "#33B006",
    yellow: "#e4e400",
    grey: "#cfcfc4",
  },
};

export default baseTheme;
