import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import DeviceSelectionList from "./DeviceSelection";
import DeviceManagement from "./DeviceManagement";
import { Box } from "@mui/material";

export const ManagementPage = () => {
  const activeDevice = useSelector(
    (state) => state.deviceManagement.activeDevice
  );
  return (
    <Box>
      <Typography variant="h5">Device Management</Typography>
      {activeDevice === null && <DeviceSelectionList />}
      {activeDevice !== null && <DeviceManagement />}
    </Box>
  );
};

export default ManagementPage;
