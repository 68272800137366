import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";

export const ModelSelection = ({ initialVal, callBack, vendor }) => {
  const [model, setModel] = React.useState();

  const dwModelList = [
    {
      label: "DW-ENHD16",
      id: 1,
    },
  ];
  React.useEffect(() => {
    if (callBack) {
      callBack(model);
    }
  }, [model]);

  const handleChange = (e) => {
    setModel(e.target);
  };

  return (
    <>
      <Autocomplete
        value={model}
        onChange={() => handleChange}
        options={dwModelList}
        renderInput={(params) => <TextField {...params} label="Model" />}
      />
    </>
  );
};
