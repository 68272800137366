import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useGetSupportedDevicesQuery } from "../../../../../store/slices/api/setupSlice";
import getTypeName from "../../../../../utils/getTypeName";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeviceTypeSelection from "../../../Setup/Configure/DeviceTypeSelection";
import PasswordInput from "../../../../Inputs/PasswordInput";
import CustomDataGrid2 from "../../../../CustomDataGrid2";
import CheckTypeSelection from "../../CheckTypeSelection";
import {
  useAddDeviceChecksMutation,
  useDeleteDeviceCheckMutation,
  useUpdateDeviceChecksMutation,
} from "../../../../../store/slices/api/managementSlice";
import AddNewCheckModal from "../AddChecksModal";

export const DeviceChecksGrid = ({
  deviceChecklistData,
  parentRow,
  refetchCallback,
}) => {
  const [rows, setRows] = React.useState(deviceChecklistData);

  const [addNewCheckModalOpen, setAddNewCheckModalOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});
  const {
    data: supportedDevices,
    isLoading: supportedDeviceLoading,
    isError: supertedDevicesErrror,
  } = useGetSupportedDevicesQuery();
  const [
    tryUpdateChecks,
    { isLoading: updateChecksLoading, isError: updateChecksError },
  ] = useUpdateDeviceChecksMutation();
  const [
    tryAddChecks,
    { isLoading: addChecksLoading, isError: addChecksError },
  ] = useAddDeviceChecksMutation();
  const [
    tryDeleteChecks,
    { isLoading: deleteChecksLoading, isError: deviceChecksError },
  ] = useDeleteDeviceCheckMutation();
  React.useEffect(() => {
    if (deviceChecklistData) {
      setRows(deviceChecklistData);
    }
  }, [deviceChecklistData]);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      let updateTableData = [...rows];

      console.log("Editing Values");
      console.log("New TableData");
      console.log(updateTableData);
      try {
        await tryUpdateChecks(values)
          .unwrap()
          .then((response) => {
            values.updated = Date.now();
            updateTableData[row.index] = values;
            setRows([...updateTableData]);
            exitEditingMode(); //required to exit editing mode and close modal
          });
      } catch {
        console.log("SOMETHING WENT HORRIBLY WRONG");
      }
    }
  };

  const handleCancelRowEdits = () => {
    console.log("Canceled");
    setRows([...rows]);
    setValidationErrors({});
  };

  const handleAddNewCheck = async (values) => {
    console.log("Values");
    console.log(values);
    console.log(parentRow);
    let content = {
      interval: values.checkInterval,
      deviceid: parentRow.original.uuid,
      checktypesiduuid: values.checkType.uuid,
    };

    try {
      await tryAddChecks(content)
        .unwrap()
        .then((response) => {
          // let updateTableData = [...rows];
          // updateTableData.push(content);
          // setRows([...updateTableData]);
        });
    } catch {
      console.log("SOMETHING WENT HORRIBLY WRONG");
    }
  };

  const handleDeleteRow = React.useCallback(
    async (row) => {
      let typeName = getTypeName(
        row.getValue("checkTypesiduuid"),
        supportedDevices ? supportedDevices.checktypes : null
      );
      let checkuuid = row.original.uuid;
      // console.log("ROW");
      // console.log(row);

      if (
        !window.confirm(
          `Are you sure you want to delete ${typeName.toUpperCase()} check from device: ${parentRow.getValue(
            "name"
          )}?`
        )
      ) {
        return;
      }

      try {
        await tryDeleteChecks(checkuuid)
          .unwrap()
          .then((response) => {});
      } catch {
        console.log("DELETE: SOMETHING WENT HORRIBLY WRONG");
      }

      // rows.splice(row.index, 1);

      // setRows([...rows]);
    },

    [rows]
  );
  const columns = [
    {
      accessorKey: "enabled",
      header: "Enabled",
      width: 150,
      Edit: ({ row, cell, column, table }) => {
        console.log("EnabledCell");
        console.log(cell);
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={cell.row.original.enabled}
                  color="primary"
                  onChange={(e) => {
                    console.log("TargetValue: " + e.target.checked);
                    row._valuesCache[cell.column.id] = e.target.checked;
                  }}
                />
              }
              label="Enabled"
            />
          </FormGroup>
        );
      },
      Cell: ({ cell }) => {
        return <Checkbox checked={cell.row.original.enabled} color="primary" />;
      },
    },
    {
      accessorKey: "checkTypesiduuid",
      header: "Type",
      width: 150,
      Edit: ({ row, cell }) => {
        return (
          <CheckTypeSelection
            initialVal={cell.getValue()}
            callBack={(val) => {
              if (val) {
                row._valuesCache[cell.column.id] = val.uuid;
              }
            }}
          />
        );
      },
      Cell: ({ cell }) => {
        let typeName = getTypeName(
          cell.getValue(),
          supportedDevices ? supportedDevices.checktypes : null
        );
        return <span>{typeName}</span>;
      },
    },
    {
      accessorKey: "uuid",
      header: "ID",
      width: 150,
      enableEditing: false,
      enableColumnOrdering: false,
      enableSorting: false,
      Edit: () => {},
    },
    {
      accessorKey: "interval",
      header: "Interval (s)",
      enableSorting: false,
      muiTableBodyCellEditTextFieldProps: {
        required: true,
        type: "number",
        variant: "outlined",
      },
    },

    {
      accessorKey: "updated",
      header: "LastUpdated",
      enableEditing: false,
      Edit: () => {},
      Cell: ({ cell }) => {
        let date = new Date(cell.getValue());
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
  ];

  return (
    <>
      <CustomDataGrid2
        title={"CheckList"}
        data={rows}
        columns={columns}
        columnVisibility={{
          uuid: false,
        }}
        enableEditing={true}
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        enableRowActions={true}
        topToolbarCustomActions={
          <Button
            color="success"
            variant="contained"
            onClick={() => setAddNewCheckModalOpen(true)}
          >
            <Add />
            Add New Check
          </Button>
        }
        // getSubRows={(originalRow) => originalRow.checklist}
        renderRowActions={({ row, table }) => (
          <Box>
            <IconButton onClick={() => table.setEditingRow(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeleteRow(row)}>
              <Delete />
            </IconButton>
          </Box>
        )}
      />
      <AddNewCheckModal
        open={addNewCheckModalOpen}
        onClose={() => setAddNewCheckModalOpen(false)}
        onSubmit={handleAddNewCheck}
      />
    </>
  );
};

export default DeviceChecksGrid;
