import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Box,
  Divider,
  Grid,
  Typography,
  List,
  styled,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SideBarItem from "./SideBarItem";
import RouteList from "../../../../_data/routes/RouteList";
import { toggleSidebarCollapse } from "../../../../store/slices/globalSlice";
import DrawerToggler from "../../../DrawerToggler";

const SidebarBox = styled(Box)(({ theme }) => ({
  //width: theme.features.global.sidebar.width,
}));
const SidebarDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.features.global.sidebar.width,
  "& .MuiDrawer-paper": {
    width: theme.features.global.sidebar.width,
    //background: theme.sideBar.backgroundColor,
  },
  alignItems: "left",
}));

const renderNavList = RouteList.map(
  (item, index) => item.level === 0 && <SideBarItem key={index} item={item} />
);

export const SidebarNav = ({ theme }) => {
  const sidebarCollapse = useSelector(
    (state) => state.global.sidebar.collapsed
  );
  return (
    <SidebarBox>
      <SidebarDrawer
        variant="persistent"
        anchor="left"
        open={sidebarCollapse}
        theme={theme}
      >
        <Grid container>
          <Grid sx={{ m: "auto" }}>
            <DrawerToggler />
          </Grid>
          <Grid sx={{ m: "auto" }}>
            <Typography variant={"h4"}>E.C.H.O</Typography>
            <Typography sx={{ textAlign: "center" }} variant={"h6"}>
              Portal
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <List>{renderNavList}</List>
      </SidebarDrawer>
    </SidebarBox>
  );
};

export default SidebarNav;
