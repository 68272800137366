import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../store/slices/globalSlice";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// export const ColorModeContext = React.createContext({
//   toggleColorMode: () => {},
// });

export const ThemeToggler = () => {
  const theme = useSelector((state) => state.global.theme);
  const dispatch = useDispatch();

  return (
    <IconButton
      active="true"
      onClick={() => dispatch(toggleTheme())}
      color="inherit"
    >
      {theme === "light" ? <DarkModeIcon/> : <LightModeIcon/>}
    </IconButton>
  );
};

export default ThemeToggler;
