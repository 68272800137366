import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const StyledBox = styled(Card)(({ theme }) => ({
  // textAlign: "center",
  padding: "5px 25px",
  ...theme.customComponents.overview,
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: ".9em",
  //fontWeight: "bold",
  textTransform: "uppercase",
}));
const StyledValue = styled(Typography)(({ theme }) => ({
  fontSize: "1.8em",
}));
export const StyledOverView = ({ header, data, isLoading, isError }) => {
  const theme = useTheme();
  const [title, setTitle] = React.useState(header ? header : "");
  const [value, setValue] = React.useState(data ? data : 0);

  React.useEffect(() => {
    if (header) {
      setTitle(header);
    }
    if (data) {
      setValue(data);
    } else {
      setValue(0);
    }
  }, [header, data]);

  if (isLoading) {
    return <Skeleton variant="rectangular" width={"150px"} height={"50px"} />;
  }

  return (
    <>
      <StyledBox>
        <StyledTitle>{title}</StyledTitle>
        <StyledValue>{value}</StyledValue>
      </StyledBox>
    </>
  );
};
