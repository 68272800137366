import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useGetUnregisteredDeviceQuery } from "../../../../../store/slices/api/setupSlice";
import styled from "@emotion/styled";

export const UnregisteredDeviceList = ({ callback }) => {
  const {
    data: unregisteredDevices,
    isLoading,
    isFetching,
    isError,
  } = useGetUnregisteredDeviceQuery(null, { pollingInterval: 5000 });
  if (isError) return <div>Error loading devices</div>;
  if (isLoading) return <div>Loading unregisted devices</div>;

  return (
    <Box>
      <h4>Select an Unregistered Device</h4>
      <List>
        {unregisteredDevices
          ? unregisteredDevices.map((device, index) => (
              <ListItemButton key={index} onClick={() => callback(device)}>
                <Box sx={{ textAlign: "center", margin: "auto" }}>
                  <Typography>{device.serial}</Typography>
                  <Typography variant="caption" display="block">
                    {" "}
                    Created: {device.created}
                  </Typography>
                </Box>
              </ListItemButton>
            ))
          : "No unregistered devices found"}
      </List>
    </Box>
  );
};

export default UnregisteredDeviceList;
