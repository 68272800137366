import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { PersistGate } from "redux-persist/integration/react";
if (process.env.NODE_ENV === "development") {
  const { worker } = require("./_data/mocks/browser");
  worker.start({
    onUnhandledRequest(req, print) {
      if (
        req.url.host.includes("googleapis.com") ||
        req.url.host.includes("gstatic.com") ||
        (req.url.host.includes("localhost:3000") &&
          !req.url.pathname.includes("fakeApi"))
      ) {
        return;
      }
      print.warning();
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          {/* <CssBaseline /> */}
          <App />
        </PersistGate>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();
