import * as React from "react";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export const NotificationSnackbar = ({ open, status, msg }) => {
  const [snackbarStatus, setSnackBarStatus] = React.useState("info");
  const [snackBarText, setSnackBarText] = React.useState("");
  const [showSnackBar, setShowSnackBar] = React.useState(false);

  React.useEffect(() => {
    setShowSnackBar(open);
  }, [open]);

  React.useEffect(() => {
    setSnackBarStatus(status);
  }, [status]);

  React.useEffect(() => {
    setSnackBarText(msg);
  }, [msg]);

  return (
    <Snackbar
      open={showSnackBar}
      autoHideDuration={2000}
      TransitionComponent={Slide}
      onClose={() => setShowSnackBar(false)}
    >
      <Alert severity={snackbarStatus}>{snackBarText}</Alert>
    </Snackbar>
  );
};
export default NotificationSnackbar;
