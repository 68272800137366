import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "dark",
  sidebar: {
    collapsed: false,
  },
};

export const globalSlice = createSlice({
  name: "Global",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    toggleSidebarCollapse: (state) => {
      state.sidebar.collapsed = !state.sidebar.collapsed;
    },
  },
});
export const { toggleTheme, toggleSidebarCollapse } = globalSlice.actions;
export default globalSlice.reducer;
