import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer, loginApi } from "./slices/api/loginSlice";
import { newDeviceSetupReducer, setUpApi } from "./slices/api/setupSlice";
import { clientAndSiteApi } from "./slices/api/clientSlice";
import globalReducer from "./slices/globalSlice";
import { checkinApi } from "./slices/api/dashboardSlice";
import {
  deviceManagementReducer,
  deviceManagementApi,
} from "./slices/api/managementSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["newDeviceSetup", "deviceManagement", "api"],
};

const reducers = combineReducers({
  global: globalReducer,
  newDeviceSetup: newDeviceSetupReducer,
  deviceManagement: deviceManagementReducer,
  login: loginReducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [clientAndSiteApi.reducerPath]: clientAndSiteApi.reducer,
  [setUpApi.reducerPath]: setUpApi.reducer,
  [checkinApi.reducerPath]: checkinApi.reducer,
  [deviceManagementApi.reducerPath]: deviceManagementApi.reducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loginApi.middleware),
});

export const persistor = persistStore(store);
