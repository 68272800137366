import * as React from "react";
import {
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Print } from "@mui/icons-material";
import styled from "@emotion/styled";

export const CustomDataGrid2 = ({
  tempRows,
  title,
  columns,
  columnVisibility,
  data,
  enableExpanding,
  enablePagination,
  enableRowVirtualization,
  enableRowActions,
  enableRowSelection,
  isLoading,
  multiRowSelection,
  onEditingRowSave,
  onEditingRowCancel,
  sorting,
  selectedRowCallback,
  renderRowActions,
  renderDetailPanel,
  topToolbarCustomActions,
  getSubRows,
  isError,

  filter,
  styleField,
  deselect,
  deselectClicked,
  height,
  noRowsOverlayText,
}) => {
  const [localRowSelection, setLocalRowSelection] = React.useState({});
  const [localRowSelectionData, setLocalRowSelectionData] = React.useState({});
  React.useEffect(() => {
    if (selectedRowCallback) {
      // console.log("LocalRowSelection");
      // console.log(localRowSelectionData.original);
      selectedRowCallback(localRowSelectionData.original);
    }
  }, [localRowSelectionData]);
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      state={{ isLoading: isLoading, localRowSelection }}
      enableExpanding={enableExpanding}
      enableRowSelection={enableRowSelection}
      getSubRows={getSubRows}
      enableMultiRowSelection={multiRowSelection}
      onEditingRowSave={onEditingRowSave}
      onEditingRowCancel={onEditingRowCancel}
      initialState={{
        columnVisibility: columnVisibility,
        sorting: sorting ?? [],
      }}
      enableRowActions={enableRowActions}
      renderRowActions={renderRowActions}
      renderDetailPanel={renderDetailPanel}
      muiTableContainerProps={{
        sx: (theme) => ({
          maxHeight: height ?? "100vh",
        }),
      }}
      muiTablePaperProps={{
        sx: (theme) => ({
          borderRadius: "0",

          //backgroundColor: "transparent",
          "& ::-webkit-scrollbar": {
            width: ".3em",
            height: ".2em",
          },
          "& ::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.background.default,
          },
          "& ::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
            backgroundColor: theme.palette.text.primary,
          },
          "& ::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }),
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          // console.log("ROW");
          // console.log(row);
          setLocalRowSelectionData(row);
          setLocalRowSelection((prev) => ({
            // ...prev,
            [row.id]: !prev[row.id],
          }));
        },
        selected: localRowSelection[row.id],
        sx: { cursor: "pointer" },
      })}
      renderToolbarInternalActions={({ table }) => (
        <Box>
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
        </Box>
      )}
      renderTopToolbarCustomActions={() => (
        <>
          {title && <Typography variant="h6">{title}</Typography>}
          {topToolbarCustomActions}
        </>
      )}

      // state={{ localRowSelection }}
    ></MaterialReactTable>
  );
};

export default CustomDataGrid2;
