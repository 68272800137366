import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetAllClientQuery } from "../../../store/slices/api/clientSlice";
import { useSelector } from "react-redux";

export const ClientSelect = ({
  initialVal,
  clientSelectedCallback,
  height,
  width,
}) => {
  const userClientID = useSelector((state) => state.login.details.clientid);
  const [client, setClient] = React.useState(null);
  const {
    data: clientList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllClientQuery();
  React.useEffect(() => {
    if (clientSelectedCallback) {
      clientSelectedCallback(client);
    }
  }, [client, clientSelectedCallback]);

  React.useEffect(() => {
    if (clientList == null) return;
    if (initialVal != null && clientList != null) {
      setClient(clientList.find((x) => x.id === initialVal));
    }
    if (initialVal == null && userClientID !== null) {
      setClient(clientList.find((x) => x.id === userClientID));
    }
  }, [clientList, initialVal, userClientID]);

  return (
    <>
      {clientList && isSuccess ? (
        <Autocomplete
          value={client}
          onChange={(event, newValue) => {
            setClient(newValue);
          }}
          options={clientList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Client" />}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientSelect;
