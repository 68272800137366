import * as React from "react";
import { Box } from "@mui/material";
import NewDeviceConfigurationForm from "./NewDeviceConfigurationForm";

export const ConfigureNewDevice = ({
  isSubmittedCallBack,
  notificationCallback,
}) => {
  return (
    <NewDeviceConfigurationForm
      isSubmittedCallBack={isSubmittedCallBack}
      notificationCallback={notificationCallback}
    />
  );
};

export default ConfigureNewDevice;
