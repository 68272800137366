import { apiSlice } from "./apiSlice";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeDevice: null,
};

export const newDeviceSetupSlice = createSlice({
  name: "newDeviceSetup",
  initialState,
  reducers: {
    selectedDevice: (state, action) => {
      console.log(action.payload);
      state.activeDevice = action.payload;
    },
    updateDevice: (state, action) => {
      console.log("UpdateDevice");
      state.activeDevice = action.payload;
    },
  },
});
export const { selectedDevice, updateDevice } = newDeviceSetupSlice.actions;
export const newDeviceSetupReducer = newDeviceSetupSlice.reducer;

export const setUpApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnregisteredDevice: builder.query({
      query: () => ({ url: `/registration/UnregisteredDevices` }),
    }),
    getUnconfirmedDevices: builder.query({
      query: () => ({ url: `/Registration/UnconfirmedDevices` }),
    }),
    registerDevice: builder.mutation({
      query: (device) => ({
        url: `/Registration`,
        method: "POST",
        body: {
          registered: true,
          serial: device.serial,
          clientid: device.client,
          siteid: device.site,
          ip: device.ip,
          name: device.name,
          typeid: device.type.uuid || device.type,
          enabled: device.enabled,
          vendorid: device.vendor.uuid || device.vendor,
          model: device.model.uuid || device.model,
          username: device.username,
          password: device.password,
          port: device.port,
          created: device.created,
          updated: device.updated,
        },
      }),
    }),
    postConfirmDevice: builder.mutation({
      query: (serial) => ({
        url: `/Registration/Confirmation`,
        method: "POST",
        body: JSON.stringify(serial),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getSupportedDevices: builder.query({
      query: () => ({
        url: `/Devices/SupportedDevices`,
      }),
    }),
    setNewConfig: builder.mutation({
      query: (content) => ({
        url: `/config/`,
        method: "POST",
        body: content,
      }),
    }),
    getDeviceConfig: builder.query({
      query: (serial) => ({
        url: `/config/${serial}`,
      }),
    }),
    getSystemDevices: builder.query({
      query: (serial) => ({
        url: `/config/${serial}`,
      }),
    }),
  }),
});

export const {
  useGetUnregisteredDeviceQuery,
  useGetUnconfirmedDevicesQuery,
  useRegisterDeviceMutation,
  useGetSupportedDevicesQuery,
  useSetNewConfigMutation,
  useGetDeviceConfigQuery,
  usePostConfirmDeviceMutation,
} = setUpApi;
