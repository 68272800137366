import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetAllSitesQuery } from "../../../store/slices/api/clientSlice";

export const SiteSelect = ({ initialVal, clientID, siteSelectedCallBack }) => {
  const [site, setSite] = React.useState(null);
  const [skip, setSkip] = React.useState(true);
  const {
    data: siteList,
    isLoading,
    isError,
  } = useGetAllSitesQuery(clientID, { skip });

  React.useEffect(() => {
    if (clientID) {
      setSkip(false);
    }
  }, [clientID]);

  React.useEffect(() => {
    if (initialVal != null && siteList != null) {
      setSite(siteList.find((x) => x.id === initialVal));
    }
  }, [siteList]);

  React.useEffect(() => {
    if (siteSelectedCallBack) {
      siteSelectedCallBack(site);
    }
  }, [site]);

  return (
    <>
      {siteList ? (
        <Autocomplete
          disablePortal
          value={site}
          onChange={(event, newValue) => {
            setSite(newValue);
          }}
          options={siteList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Sites" />}
        />
      ) : (
        <>Please select a client </>
      )}
    </>
  );
};

export default SiteSelect;
