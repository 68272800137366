import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const PasswordInput = ({
  onBlur,
  device,
  onChangeCallBack,
  passwordError,
  passwordHelperText,
}) => {
  const [error, setError] = React.useState(passwordError ?? null);
  const [helperText, setHelperText] = React.useState(
    passwordHelperText ?? null
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  React.useEffect(() => {
    if (onChangeCallBack) {
      onChangeCallBack(password);
    }
  }, [password]);

  return (
    <FormControl>
      <InputLabel>Password</InputLabel>
      <OutlinedInput
        label={"Password"}
        type={showPassword ? "text" : "password"}
        value={device ? device.password : password}
        onBlur={onBlur ? onBlur : null}
        onChange={(e) => setPassword(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        helpertext={passwordError && helperText}
        error={passwordError}
      />
    </FormControl>
  );
};

export default PasswordInput;
