import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  IconButton,
  Checkbox,
  Grid,
  Skeleton,
  Typography,
  Icon,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import getTypeName from "../../../../../utils/getTypeName";
//import { useGetDeviceConfigQuery } from "../../../../../store/slices/api/setupSlice";
import {
  useGetEchoDevicesWChecksQuery,
  useUpdateDeviceConfigurationMutation,
} from "../../../../../store/slices/api/managementSlice";
import { useGetSupportedDevicesQuery } from "../../../../../store/slices/api/setupSlice";
import EncoderDetails from "../DeviceDetails/EncoderDetails";
import DeviceManagementToolbar from "./DeviceFilterToolBar";
import CameraDetailsDataGrid from "../DeviceDetails/CameraDetailsDataGrid";
import CustomDataGrid2 from "../../../../CustomDataGrid2";
import { Edit } from "@mui/icons-material";
import DeviceTypeSelection from "../../../Setup/Configure/DeviceTypeSelection";
import DeviceChecksGrid from "../DeviceChecksGrid";
import VendorSelection from "../../VendorSelection";

const tempRows = [];

export const DeviceManagementDataGrid = ({ echoSystemID, allowRefresh }) => {
  const [skip, setSkip] = React.useState(true);
  const [rows, setRows] = React.useState(tempRows);
  const [validationErrors, setValidationErrors] = React.useState({});
  const {
    data: devices,
    isLoading,
    isError,
    refetch,
  } = useGetEchoDevicesWChecksQuery(echoSystemID, { skip });
  const {
    data: supportedDevices,
    isLoading: supportedDeviceLoading,
    isError: supertedDevicesErrror,
  } = useGetSupportedDevicesQuery();
  const [
    tryUpdateDevice,
    { isLoading: updateDeviceLoading, isError: updateDeviceError },
  ] = useUpdateDeviceConfigurationMutation();

  const validateRequired = (value) => !!value.length;
  const validateIP = (value) => !!value.length;
  const validateName = (value) => !!value.length;

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "IP"
              ? validateIP(event.target.value)
              : cell.column.id === "name"
              ? validateName(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  React.useEffect(() => {
    if (devices && devices) {
      setRows(devices);
    }
  }, [devices]);

  React.useEffect(() => {
    if (echoSystemID !== undefined && echoSystemID.length !== 0) {
      setSkip(false);
    }
  }, [echoSystemID]);

  const columns = [
    {
      accessorKey: "enabled",
      header: "Enabled",
      Edit: ({ cell, row, column, table }) => {
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={cell.row.original.enabled}
                  color="primary"
                  onChange={(e) => {
                    console.log("TargetValue: " + e.target.checked);
                    row._valuesCache[cell.column.id] = e.target.checked;
                  }}
                />
              }
              label="Enabled"
            />
          </FormGroup>
        );
      },
      Cell: ({ cell }) => {
        return <Checkbox checked={cell.row.original.enabled} color="primary" />;
      },
    },
    {
      accessorKey: "uuid",
      header: "ID",
      width: 150,
      enableEditing: false,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: "ip",
      header: "IP",
      width: 150,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: "name",
      header: "Name",
      width: 150,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: "username",
      header: "Username",
      width: 150,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: "password",
      header: "Password",
      width: 150,
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: "typeid",
      header: "Type",
      width: 150,
      Edit: ({ row, cell }) => {
        return (
          <DeviceTypeSelection
            initialVal={cell.getValue()}
            callBack={(val) => {
              if (val) {
                row._valuesCache[cell.column.id] = val.uuid;
              }
            }}
          />
        );
      },
      Cell: ({ cell }) => {
        let typeName = getTypeName(
          cell.getValue(),
          supportedDevices ? supportedDevices.devicetypes : null
        );
        return <span>{typeName}</span>;
      },
    },
    {
      accessorKey: "vendorid",
      header: "Vendor",
      Edit: ({ cell }) => {
        return <VendorSelection initialVal={cell.getValue()} />;
      },
      Cell: ({ cell }) => {
        let typeName = getTypeName(
          cell.getValue(),
          supportedDevices ? supportedDevices.devicetypes : null
        );
        return <span>{typeName}</span>;
      },
    },
    {
      accessorKey: "updated",
      header: "LastUpdated",
      enableEditing: false,
      Cell: ({ cell }) => {
        let date = new Date(cell.getValue());
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
  ];
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      if (!Object.keys(validationErrors).length) {
        let updateTableData = [...rows];

        console.log("Editing Values");
        console.log("New TableData");
        console.log(updateTableData);
        try {
          await tryUpdateDevice(values)
            .unwrap()
            .then((response) => {
              values.updated = Date.now();
              updateTableData[row.index] = values;
              refetch();
              // setRows([...updateTableData]);
              exitEditingMode(); //required to exit editing mode and close modal
            });
        } catch {
          console.log("SOMETHING WENT HORRIBLY WRONG");
        }
      }
    }
  };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  if (isLoading) {
    return (
      <>
        <h5>Retrieving data.</h5>
        <h5>Please wait</h5>
      </>
    );
  }
  if (isError) {
    return (
      <>
        <p>Error Loading Data Please refresh</p>
        <Button onClick={refetch}>Refresh</Button>
      </>
    );
  }

  return (
    <>
      <Box>
        <Button onClick={refetch}>Refresh</Button>
        <CustomDataGrid2
          title={"Device Name"}
          height={"55vh"}
          tempRows={rows}
          columns={columns}
          isLoading={isLoading}
          isError={isError}
          data={rows}
          renderDetailPanel={({ row }) => (
            <Box>
              <DeviceChecksGrid
                deviceChecklistData={row.original.checklist}
                parentRow={row}
              />
            </Box>
          )}
          // enableExpanding={true}
          enableRowActions={true}
          // getSubRows={(originalRow) => originalRow.checklist}
          renderRowActions={({ row, table }) => (
            <Box>
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Box>
          )}
          enableEditing={true}
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          columnVisibility={{
            uuid: false,
            siteID: false,
            username: false,
            password: false,
            lastCheck: false,
          }}
        />
      </Box>
    </>
  );
};

export default DeviceManagementDataGrid;
