import * as React from 'react';
import { Card } from '@mui/material';
import styled from '@emotion/styled';

const StyledCard = styled(Card)(({ theme }) => ({
    margin:'auto',
    width: 'auto',
    padding: '30px'
  }));

const StepperDetailCard = ({children})=>{
    
    return(
        <StyledCard>
            {children}
        </StyledCard>
    )
    }
export default StepperDetailCard;
